import 'react-quill/dist/quill.snow.css';


import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import ReactQuill, { Quill } from 'react-quill';
import { Wrapper } from '../components';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import jsPDF from 'jspdf'
import PDFViewer from 'pdf-viewer-reactjs'
import { RenderPageProps, Viewer } from '@react-pdf-viewer/core';
import TextField from "@material-ui/core/TextField";

import ReactLoading from 'react-loading';

import Barcode from 'react-barcode'

import bwipjs from 'bwip-js';

(function(API){
  API.myText = function(txt, options, x, y) {
      options = options ||{};
      /* Use the options align property to specify desired text alignment
       * Param x will be ignored if desired text alignment is 'center'.
       * Usage of options can easily extend the function to apply different text 
       * styles and sizes 
      */
     var x=0
      if( options.align == "center" ){
          // Get current font size
          var fontSize = this.internal.getFontSize();

          // Get page width
          var pageWidth = this.internal.pageSize.getWidth();
          console.log(pageWidth)

          // Get the actual text's width
          /* You multiply the unit width of your string by your font size and divide
           * by the internal scale factor. The division is necessary
           * for the case where you use units other than 'pt' in the constructor
           * of jsPDF.
          */
          var txtWidth = this.getStringUnitWidth(txt)*fontSize/this.internal.scaleFactor;
          console.log(txtWidth)
          // Calculate text's x coordinate
          x = ( pageWidth - txtWidth ) / 2;
      }
      this.line(x,152,x+txtWidth,152)
      // Draw text at x,y
      console.log(x)
      console.log(y)
      console.log(txt)
      this.text(txt,x,y);
  }
})(jsPDF.API);

export default class RadiologyPDF extends React.Component {
  constructor (props) {
    super(props)
    this.state = { 
      editorHtml: '', theme: 'snow', pdf:"",   
      pdfHtml:"",
      PurePDF:"",
      DoctorName:"",
      DoctorInfo:"",
      loading:true,
  margins :{
    top: 160,
      bottom: 210,
      left: 70,
      width: 450
     } }
     
    this.handleChange = this.handleChange.bind(this)
    this.generate = this.generate.bind(this)
    this.svgString2Image=this.svgString2Image.bind(this)
    this.base64toBlob=this.base64toBlob.bind(this)

  }
  componentDidMount(){
    var obj={
        Invoice_Aux_ID:this.props.Invoice_Aux_ID+"",
        User_ID:localStorage.getItem("user_id"),
    }
    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/RadiologyTestsResultsViewModify"
           
    fetch(url,{
      method: 'POST',
      'Content-Type':"application/json",
      body:JSON.stringify(obj)
    }).then((res)=>res.json()).then((res)=>{
      console.log(res)
       document.getElementById('preview').innerHTML=res[0].HTML_Report
       this.setState({editorHtml:res[0].HTML_Report})
       this.setState({loading:false})
       this.setState({DoctorName:res[0].User_DoctorName})
       this.setState({DoctorInfo:res[0].DoctorDegree})
    })
      
  }
 base64toBlob = (string) => {
   
    const bytes = atob(string);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: 'application/pdf' });
};
  svgString2Image=(svgString, width, height, format, callback)=>{
    
    var temp=document.getElementById('barcode').innerHTML
    // set default for format parameter
    format = format ? format : 'jpg';
    // SVG data URL from SVG string
    var svgData = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(temp)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement('canvas');
    // get canvas context for drawing on canvas
    var context = canvas.getContext('2d');
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = new Image();
    // later when image loads run this
    image.onload = function () { // async (happens later)
        // clear canvas
        context.clearRect(0, 0, width, height);
        // draw image with SVG data to canvas
        context.drawImage(image, 0, 0, width, height);
        // snapshot canvas as png
        var pngData = canvas.toDataURL('image/jpeg');
        // pass png data URL to callbac
        callback(pngData)
    }; // end async
    image.src=svgData
  
  }
  handleChange (html) {

    var manipulated_html=html.replaceAll('class="ql-size-small"','style="font-size:10px"')
    manipulated_html=manipulated_html.replaceAll('<p','<p style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<li','<li style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll('<ol','<ol style="font-size:16px" ')
    manipulated_html=manipulated_html.replaceAll( '<br>','<p><span style="color: rgb(255, 255, 255);">Space</span></p>')
  
    document.getElementById("preview").innerHTML=manipulated_html
    console.log(manipulated_html)
  this.setState({ editorHtml: html });
  }
  
  handleThemeChange (newTheme) {
    if (newTheme === "core") newTheme = null;
    this.setState({ theme: newTheme })
  }



generate(name,refBy,invoice_id,age_gender,Salutation,TestTitle, InvoiceDate, InvoiceTime,UserName, DoctorName,DoctorInfo,Ref_No,ReportDate,ReportTime)
{
  

  this.svgString2Image(this.props.barcode,800,600,'jpg',(barcode)=>{
      

	var pdf = new jsPDF('p', 'pt', 'a4');
    pdf.setFontSize(10);
    pdf.setFont('arial','normal')
    var manipulated_html=document.getElementById('preview').innerHTML.replaceAll('style="font-size:10px"','style="font-size:12px"')
    manipulated_html=manipulated_html.replaceAll('<p style="font-size:16px"','<p style="font-size:12px"')
    manipulated_html=manipulated_html.replaceAll('<li style="font-size:16px"','<li style="font-size:12px"')
    manipulated_html=manipulated_html.replaceAll('<ol style="font-size:16px"','<ol style="font-size:12px"')

    var elementHandler = {
      '#ignorePDF': function (element, renderer) {
        return true;
      }
    };
    
    pdf.fromHTML(manipulated_html, 
    70, // x coord
    155,
           {
               // y coord
               width: this.state.margins.width// max width of content on PDF,
               ,'elementHandlers': elementHandler

           },function(dispose) {
         var totalpages=pdf.internal.getNumberOfPages()
         for(var i = totalpages; i >= 1; i--)
         {
           pdf.setPage(i);                            
             //header
             pdf.setFontStyle('normal');
             //header Image
             pdf.setFillColor(242, 240, 240);
             pdf.rect(268, 50, 70, 18, "F");
             pdf.rect(268, 50, 70, 18, "F");
             pdf.line(268,68,338,68) //Bottom
             pdf.line(268,50,338,50) // Top
             pdf.line(268,50,268,68) //Left
             pdf.line(338,50,338,68) //Right
           
             
            // MAIN HEADING
            pdf.setFontStyle('bold');
            pdf.setFontSize(18);
           
            pdf.myText(TestTitle,{align: "center"},70,150);
           
             pdf.setFontStyle('bold');
             pdf.setFontSize(7.5);
      
             pdf.text("FSLS ID : "+invoice_id, 275, 62 );
             pdf.setFontSize(8.5);
             // Barcode Image
           pdf.addImage(barcode, 'JPG', 400, 10, 170,40); 
             pdf.text(Salutation+" "+name, 410, 60 );
             pdf.setLineWidth(0.1);
             pdf.line(410, 65, 560,65); // Name Line
             pdf.line(410, 80, 560,80); // Age Line
             pdf.line(410, 95, 560,95); // Ref By Line
             pdf.line(410, 110, 560,110); // Ref By Line
           
             if(Ref_No!=""){
              pdf.line(410, 125, 560,125); // Ref No By Line
             }
            
             pdf.setFontSize(7.5);
             pdf.setFontStyle('normal');
             pdf.text("Age/Gender", 410, 75);
             pdf.text(":  "+age_gender, 465, 75);
             pdf.text("Report Date/Time", 410, 90);
             pdf.text(":  "+ReportDate+" "+ReportTime, 465, 90);
             pdf.text("Ref By", 410, 105);
             pdf.text(":  "+refBy, 465, 105);
             console.log("Reference No")
             console.log(Ref_No)
             if(Ref_No!=""){
              pdf.text("Ref No", 410, 120);
              pdf.text(":  "+Ref_No, 465, 120);
             }
               // Signing Start
            
               pdf.setFontSize(7);
               pdf.text("User ID : "+UserName+" Inv. Date : "+InvoiceDate+" Inv. Time : "+InvoiceTime,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-200,"right");
               pdf.setFontStyle('bold');
               pdf.setFontSize(9);
  
               pdf.setTextColor(255,0,0)
               pdf.text("This is a digitally verified Report",pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-190,"right");
               pdf.setTextColor(0,0,0)
               pdf.text(DoctorName,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-180,"right");
               pdf.setFontStyle('normal');
               var margin=170
               var degree=DoctorInfo.split('|')
               degree.map((item)=>{
                 pdf.text(item,pdf.internal.pageSize.getWidth()-70 , pdf.internal.pageSize.getHeight()-margin,"right");
                 margin=margin-10
               })
               // Signing End
               if(totalpages>1){
                pdf.text("Page "+i+" of "+totalpages,70 , pdf.internal.pageSize.getHeight()-180,"left");
               
               } 
             pdf.setLineCap(2);
             pdf.setLineCap(2);
             //footer
             // Footer
             
             
             pdf.page++;
         }
           }, 
       this.state.margins);
           this.setState({PurePDF:pdf.output('datauristring')})
    const blob = this.base64toBlob(pdf.output('datauristring').split('base64,')[1]);
    const url = URL.createObjectURL(blob);
       this.setState({pdfHtml:url})
  })
 
};

  render () {
    return (
      <Wrapper>
          <div id="preview" hidden>

</div>
          {this.state.loading ? 
     <center>
     <ReactLoading type={'spinningBubbles'} color={'black'}  height={'10em'} width={'10em'}/>
     </center>
     :
      <Card className="overflow-visible">
     
        <div style={{
          textAlign:'center',
        
        }}>  <Button variant="contained" color="primary" onClick={()=>{
            this.setState({pdfHtml:""})
            this.setState({PurePDF:""})
            
            this.generate(this.props.name,this.props.refBy,this.props.invoice_id,this.props.age_gender, this.props.Salutation,this.props.TestTitle, this.props.InvoiceDate,this.props.InvoiceTime,this.props.UserName,this.props.DoctorName,this.props.DoctorInfo,this.props.Ref_No,this.props.ReportDate,this.props.ReportTime)
        }}>Generate PDF Preview</Button></div>
        <Grid container style={{
            height:"800px"
        }}>

         <div id="barcode" hidden>
        
         <Barcode value={this.props.invoice_id} displayValue={false} />
        </div>
       
       
        
         <Grid item lg={12} md={12}  style={{
              border:"1px solid black",
              background:"black",
              height:"800px"
          }}>
       {
              this.state.pdfHtml!="" ?
              <iframe src={this.state.PurePDF}  
              
              style={{
                  width:"100%",
                  height:"100%"
              }}
              allowfullscreen></iframe>
            
          
          :<div
          style={{
              alignItems: 'center',
              color:"white",
              border: '2px dashed rgba(255,255,255)',
              display: 'flex',
              fontSize: '2rem',
              height: '100%',
              justifyContent: 'center',
              width: '100%',
          }}
      >
          Preview area
      </div>
  
          }
           </Grid>
        
      </Grid>
     
        </Card>
  }
    </Wrapper>
     )
  }
}

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
RadiologyPDF.modules = {
  toolbar: [
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': ['10px','12px'] }],
    [{ 'align': [] }],
  
    ['clean'] 
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
