import React,{useEffect} from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import { Typeahead } from "react-bootstrap-typeahead";
import Button from "@material-ui/core/Button";

import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import FormGroup from '@material-ui/core/FormGroup';

import RadioGroup from '@material-ui/core/RadioGroup';

import FormLabel from '@material-ui/core/FormLabel';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import MenuItem from '@material-ui/core/MenuItem';


import Select from '@material-ui/core/Select';

import Autocomplete from "@material-ui/lab/Autocomplete";

import InputLabel from '@material-ui/core/InputLabel';


import DateFnsUtils from '@date-io/date-fns';

import { withStyles } from '@material-ui/core/styles';

import DialogActions from "@material-ui/core/DialogActions";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import Slide from "@material-ui/core/Slide";

import Modal from "@material-ui/core/Modal";

import PictureAsPdfSharpIcon from "@material-ui/icons/PictureAsPdfSharp";
import Switch from "@material-ui/core/Switch";

import Invoice_Table from "./table";

import MaterialTable from "material-table";


import FormControl from "@material-ui/core/FormControl";
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import { addYears, set, max } from "date-fns";

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
function msToTime (ms) {
  var seconds = (ms/1000);
  var minutes = parseInt(seconds/60, 10);
  seconds = seconds%60;
  var hours = parseInt(minutes/60, 10);
  minutes = minutes%60;
  
  return addZero(hours) + ':' + addZero(minutes) ;
}

function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  button:{
    color:'white' 
  },
  paper: {
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    margin:'auto',
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
    float: "right",
  },
}));
 
const formatAMPM=(date)=>{
  var hours = date.getHours();
  var minutes = date.getMinutes()
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes
  return strTime;
}


export default function Invoice() {
  const classes = useStyles();
 const [classicModal,setClassicModal]=React.useState(false)
  const [search_table, setSearch_table] = React.useState([]);
  const [age, setAge] = React.useState("");
  const [lab_tests, setLab_test] = React.useState([]);
  const [branches,setBranches]=React.useState([])
  const [branch,setBranch]=React.useState("")
  const [columns, setColumns] = React.useState([
    { title: 'Code', field: 'code', editable: 'never'},
    { title: 'Title', field: 'title', editable: 'never' },
    { title: 'Description', field: 'description', type: 'numeric' ,editable: 'never' },
    { title: 'Cost', field: 'cost', type: 'numeric'  ,editable: 'never' ,render: rowData => {
      return <p style={{ color: "brown", fontWeight: "bold" }}>{rowData.cost}</p>   
    } },
    { title: 'Discount %', field: 'discount_percent', type: 'numeric', render: rowData => {
      return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_percent+"%"}</p>   
    }  },
    { title: 'Discount Value', field: 'discount_value', type: 'numeric' ,editable: 'never',render: rowData => {
      return <p style={{ color: "blue", fontWeight: "bold" }}>{rowData.discount_value}</p>   
    }  },
    { title: 'Special Discount', field: 'special_discount', type: 'numeric' ,editable: 'never',render: rowData => {
      return <p style={{ color: "red", fontWeight: "bold" }}>{rowData.special_discount}</p>   
    }  },
    { title: 'Net Cost', field: 'net_cost', type: 'numeric' ,editable: 'never',  render: rowData => {
      return <p style={{ color: "#008240", fontWeight: "bold" }}>{rowData.net_cost}</p>   
    }
  }
  ]);


  const [cc_columns, setCC_columns] = React.useState([
    { title: 'Date', field: 'date', editable: 'never'},
    { title: 'Cheque/ Transaction #', field: 'cc', editable: 'never' },
    { title: 'Amount', field: 'amount', type: 'numeric' ,editable: 'never' },
    { title: 'Remarks', field: 'remarks',editable: 'never'},
    { title: 'Mode', field: 'mode',editable: 'never'},
    { title: 'Shift ID', field: 'shift_id',editable: 'never'},
    { title: 'User ID', field: 'user_id',editable: 'never'},
    { title: 'User Name', field: 'user_name',editable: 'never'}
  ]);
  
  
  const [data, setData] = React.useState([]);

  const [CC, setCC] = React.useState([]);
 
  
  const [consentForm,setconsentForm]=React.useState('')

  const [patient_history, setPatientHistory] = React.useState([]);
  const [panel, setPanel] = React.useState("");
  const [total_bill, setTotal_Bill] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [selectOption, setSelectOption] = React.useState({});
  const [openHist, setOpenHist] = React.useState();
  const [ticket_no,setTicket_No]=React.useState('')
  const [TempDiscount,setTempDiscount]=React.useState(0)
  const [flight_no,setFlight_No]=React.useState('')
  const [flight_date,setFlight_Date]=React.useState(new Date('01/01/1900'))
  const [flight_time,setFlight_time]=React.useState(0)

const [Entitlement_Id,setEntitlement_Id]=React.useState(0)
const [Entitlement_Name,setEntitlement_Name]=React.useState('')
const [Entitlement_Amount,setEntitlement_Amount]=React.useState(0)

  const [airline,setAirline]=React.useState('')
  const [destination,setDestination]=React.useState('')
  const [airport,setAirport]=React.useState('')
  const [NormalCase,setNormalCase]=React.useState(true)
  const [country,setCountry]=React.useState('')
  const [CNIC,setCNIC]=React.useState('')
  const [saveInvoice,setSaveInvoice]=React.useState(false)
  const [passport,setPassport]=React.useState('')
  const [salesAgent,setSalesAgent]=React.useState('Select')
  const [salesAgentNameList,setSalesAgentNameList]=React.useState([])

  const [DoctorNameList,setDoctorNameList]=React.useState([])

  const [salesAgentList,setSalesAgentList]=React.useState([])
  const [selectBranch,setSelectBranch]=React.useState('')
  const [panelList,setPanelList]=React.useState([])
  const [otherPanelCase,setOtherPanelCase]=React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState('Normal');
  const [TestList,setTestList]=React.useState([])
  const [total_cost,setTotal_cost]=React.useState(0)
  const [total_discount_value,setTotalDiscountValue]=React.useState(0)
  const [total_special_discount,setTotalSpecialDiscount]=React.useState(0)
  const [total_net_cost,setTotalNetCost]=React.useState(0)

  const [Comments_for_Patient,setComments_for_Patient]=React.useState('')
  const [Invoice_Remarks,setInvoiceRemarks]=React.useState('')

  const [Selected_Test_Code_List,setSelected_Test_Code_List]=React.useState([])


  const [cc_amount,setCCAmount]=React.useState(0)
  const [cc_date,setCCDate]=React.useState(new Date())
  const [cc_remarks,setCCRemarks]=React.useState('')
  const [cc_num,setCCNum]=React.useState('')

  const [Invoice_Total_Value,setInvoiceTotalValue]=React.useState(0)
  const [Discount,setDiscount]=React.useState(0)
  const [NetPayableValue,setNetPayableValue]=React.useState(0)

const [Received_Payment,setReceived_Payment]=React.useState(0)
const [Balance_Payment,setBalance_Payment]=React.useState(0)

  const [cash,setCash]=React.useState(true)
  const [credit_card,setCreditCard]=React.useState(false)
  const [dd,setDD]=React.useState(false)
  const [cheque,setCheque]=React.useState(false)

  const [DoctorList,setDoctorList]=React.useState([])
  const [Ref_By_Dr,setRef_By_Dr]=React.useState('')
  const [Ref_No,setRef_No]=React.useState('')

  const [DiscountPrice,setDiscountPrice]=React.useState(0)
const handleFileRead = async (event) => {
    const file = event.target.files[0]
    var fileInput = document.getElementById('img');
    var filePath = fileInput.value;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    if(!allowedExtensions.exec(filePath)){
        alert('Please upload file having extensions .jpeg/.jpg/.png only.');
        fileInput.value = '';
        setconsentForm('')
        return false;
    }else{
     
    const base64 = await convertBase64(file)
    setconsentForm(base64)  
    }

  }
 const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  const handleChange = (event) => {
    setSaveInvoice(true)
    console.log(event.target.value)
    setSelectedValue(event.target.value);
    if(event.target.value=='AR-01033'){
      setCountry('Saudi Arabia')
    }else{
      setCountry('Other Country Except Saudi Arabia')
    }
    if(event.target.value=='AR-01042'){
      setAirline('PIA')
    }
    if(event.target.value=='AR-01037'){
      setAirline('FlyDubai')
    }
    if(event.target.value=='AR-01036'){
      setAirline('Emirates')
    }
    if(event.target.value=='AR-01044'){
      setAirline('Air Arabia')
    }
    if(event.target.value=='AR-01059'){
      setAirline('Qatar Airways')
    }
    if(event.target.value=='AR-01068'){
      setAirline('AirBlue')
    }
    if(event.target.value=='AR-01062'){
      setAirline('British Airways')
    }
    if(event.target.value=='AR-01063'){
      setAirline('Virgin Atlantic')
    }
    if(event.target.value=='AR-01064'){
      setAirline('Oman Air')
    }
    if(event.target.value=='AR-01065'){
      setAirline('Gulf Air')
    }
    if(event.target.value=='AR-01067'){
      setAirline('Salam Air')
    }
    if(event.target.value=='AR-01069'){
      setAirline('Airways MPL')
    }
    if(event.target.value!='AR-01069' && event.target.value!='AR-01067' && event.target.value!='AR-01065' && event.target.value!='AR-01064' && event.target.value!='AR-01063' && event.target.value!='AR-01062' && event.target.value!='AR-01068' && event.target.value!='AR-01033' && event.target.value!='AR-01042' && event.target.value!='AR-01037' && event.target.value!='AR-01036' && event.target.value!='AR-01044' && event.target.value!='AR-01059' && event.target.value!='Normal'){
      setFlight_No('')
      setTicket_No('')
      setAirline('')
      setDestination('')
      setAirport('')
      setOtherPanelCase(true)
    }else{
      setOtherPanelCase(false)
    }
    if(event.target.value=='Normal'){
      setFlight_No('')
      setTicket_No('')
      setAirline('')
      setDestination('')
      setAirport('')
      setData([])
      setTotal_cost(0)
      setTotalSpecialDiscount(0)
      setTotalNetCost(0)
      setBalance_Payment(0)
      setReceived_Payment(0)
      setCC([])
      setSelected_Test_Code_List([])
      setNormalCase(true)
    }else{
      setData([])
      setTotal_cost(0)
      setTotalSpecialDiscount(0)
      setTotalNetCost(0)
      setBalance_Payment(0)
      setReceived_Payment(0)
      setSelected_Test_Code_List([])
      setCC([])
      setNormalCase(false)      
    }
  };
  useEffect(()=>{
    var id=localStorage.getItem('branch_id')
    var name=localStorage.getItem('patient_name')
    setBranch(id)
    var mob_num=localStorage.getItem("patient_mobile")
    setName(name)
    setMobile(mob_num)
    if(localStorage.getItem('user_id')==null || localStorage.getItem('user_id')==undefined){
      window.location.href="https://radiology.fslabs.com.pk/login"
    }
    if(localStorage.getItem('org_branch_id')==null ||localStorage.getItem('org_branch_id')==undefined ){
      localStorage.clear()
      window.location.href="https://radiology.fslabs.com.pk/login"
    }
    if(localStorage.getItem('org_branch_id')=='0'){
      fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BookingSalesAgentsCallsUp").then((res)=>res.json()).then((response)=>{
    setSalesAgentList(response)
    var obj={}
    response.map((item)=>{
      obj[item.Agent_ID]=item.Agent_Name
    })
    setSalesAgentNameList(obj)

    })
    fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetPanelsListAll").then((res)=>res.json()).then((response)=>{
      response.push({
        Panel_Code:"Normal",
        Panel_Title:"Normal Patient"
      })
      setPanelList(response)
      })
      fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_DoctorsListCallsUp").then((res)=>res.json()).then((response)=>{
        setDoctorList(response)
        var obj={}
        response.map((item)=>{
          obj[item.DoctorID]=item.DoctorName
        })
        setDoctorNameList(obj)

        })

    }
   

    

          
fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/BranchesLoadAllfromWebserver").then(res=>res.json()).then((response)=>{
  setBranches(response)
  var obj={}
  response.map((item)=>{
    obj[item.BranchID]=item.BranchName
  })
  setSelectBranch(obj)
  fetch("https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetTestsPriceList?vQuery={%22PIN%22:%22%22,%22PhoneNo%22:%22%22,%22PatientName%22:%22%22,%22RegDateFrom%22:%222000-02-01T04:08:27.995Z%22,%22RegDateTo%22:%222020-08-12T04:08:27.995Z%22}&vUID=120720001&vRole=Patient").then((res)=>{
    return res.json()
  }).then((response)=>{
setTestList(response)
  })
})

  },[])

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenHist = () => {
    setOpenHist(true);
  };
  function calculateAge(birthday) {
    var ageDifMs = Date.now() - birthday.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseHist = () => {
    setOpenHist(false);
  };
  const [name, setName] = React.useState("");
  const [mobile, setMobile] = React.useState("");
  const [id, setId] = React.useState("");
  const [date,setDate]=React.useState(new Date())
  const search_patient = () => {
    console.log(name)
    fetch(
      "https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_GetsPatientsList?vName=" +
        name +
        "&vMobile=" +
        mobile +
        "&vID=" +
        id
    )
      .then((res) => res.json())
      .then((response) => {
        var data = [];
        response.map((item) => {
          console.log(item)
          var obj = {
            Patient_ID: item.Patient_ID,
            Patient_Name: item.Patient_Name,
            MobileNo: item.MobileNo,
            ToAddress: item.ToAddress,
            eMail: item.eMail,
          cnic:item.CNIC,

            select: (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  Populate(
                    item.Patient_ID,
                    item.Patient_Name,
                    item.MobileNo,
                    item.Panel_Name,
                    item.Patient_DOB,
                    item.CNIC,
                    item.Passport,
                    item.Panel_Code,
                    item.Entitlement_ID,
                    item.Entitlement_Name,
                    item.Entitlement_Balance
                    
                  );
                }}
              >
                Select
              </Button>
            ),
          };

          data.push(obj);
        });
        setSearch_table(data);
        handleOpen(true);
      });
  };
  Date.prototype.ddmmyyy = function () {
    var mm = this.getMonth() + 1; // getMonth() is zero-based
    var dd = this.getDate();

    return [
      (dd > 9 ? "" : "0") + dd,
      (mm > 9 ? "" : "0") + mm,
      this.getFullYear(),
    ].join("/");
  };
  const Call_Patient_History = () => {
    if (id != undefined && id.trim() != "") {
      fetch(
        "https://eviewer.fslabs.com.pk:5443/4DACTION/WebPortal_PatientHistoryCallsUp/" +id
      )
        .then((res) => res.json())
        .then((result) => {
          var the_rows = [];
          if(result.Result==false){
            alert("Sorry, No Record Found!!")
return
          }
          result.map((data) => {
            var ds = new Date(data.Booking_DATE).ddmmyyy();
            var time = msToTime(data.Booking_TiME);
            var obj = {
              id: data.Invoice_ID,
              booking_date:ds,
              booking_time:time,
              test_code:data.Test_CODE,
              test_title:data.Test_Name,
              amount:data.Test_Price,
              discount:data.Discount,
              final:data.Final_Price
            };
            the_rows.push(obj);
          });
          console.log(the_rows);
          setPatientHistory(the_rows);
          handleOpenHist();
        });
    } else {
      alert("Kindly Search Patient First!");
    }
  };
  const Populate = (id, name, mobile, panel, dob, cnic, passport, panel_code, entitlement_id, entitlement_name,entitlement_balance) => {
    setId(id);
    setName(name);
    var age = new Date(dob);
    setAge(calculateAge(age));
    setCNIC(cnic)
    setPassport(passport)
    setMobile(mobile);
    setPanel(panel);
    setEntitlement_Id(entitlement_id)
    setEntitlement_Name(entitlement_name)
    setEntitlement_Amount(entitlement_balance)
    if(entitlement_name!=""){
      setDiscount(100)
      setTempDiscount(100)
      
    }
    if(entitlement_name==""){
      handleChange({
        target:{
          value:"Normal"
        }
      })
      setSalesAgent("Select")
      setRef_By_Dr("")
    }
    setRef_By_Dr("")
    setNormalCase(true)
    setRef_No('')
    setData([])
    setTotal_cost(0)
    setTotalSpecialDiscount(0)
    setTotalNetCost(0)
    setBalance_Payment(0)
    setReceived_Payment(0)
    setSelected_Test_Code_List([])
    setCC([])
    setDiscountPrice(0)
    setDiscount(0)
    setTempDiscount(0)
  if(panel_code!="" && entitlement_name==""){
    handleChange({
      target:{
        value:panel_code
      }
    })
  }
    handleClose(false);
  };
  
  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <div style={{
          margin:'auto',
          padding:10
        }}>
          
        {
               localStorage.getItem('org_branch_id')=='0' ?
               <FormControl variant="filled" className={classes.formControl}  style={{width:"30em"}} >
               <InputLabel id="demo-simple-select-filled-label">Branch</InputLabel>
               <Select
                 labelId="demo-simple-select-filled-label"
                 id="demo-simple-select-filled"
                 value={branch}
                 onChange={(e)=>{
                   var result=window.confirm('Are you Sure ?. Your want to change Branch?')
                  if(result){
                    setBranch(e.target.value)
                  localStorage.setItem('branch_id',e.target.value)
                  localStorage.setItem('branch_name',selectBranch[e.target.value])
                  window.location.reload()
                  }
                  
                 }}
               >
                 <MenuItem value={"0"} disabled>Select</MenuItem>
                    {
                      branches.map((item)=>{
                       return <MenuItem value={item.BranchID} key={item.BranchID}>{item.BranchName.split('MPL, ')[1]}</MenuItem>
                         
                      })
                    }
               </Select>
             </FormControl>
             :null
             }
          </div>
        <div>
         

        </div>
        <div style={{
          textAlign:'center'
        }}><b>Search Patients using Patient ID, Patient Name & Mobile Number</b></div>
        <div style={{ display: "-webkit-inline-box", width: "100%" }}>
          <form onSubmit={(e)=>{ 
           e.preventDefault();
            search_patient()}} >
          <TextField
            id="outlined-required"
            label="Patient ID"
            value={id}
            onChange={(e) => {
              setId(e.target.value);
            }}
            variant="outlined"
          />
<input type="submit" style={{
  position: 'absolute',
  left: '-9999px'
  }}/>
          <TextField
           id="outlined-required"
            label="Patient Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant="outlined"
          />
          <TextField
            id="outlined-required"
            value={mobile}
            onChange={(e) => {
              setMobile(e.target.value);
            }}
            label="Patient Mobile"
            variant="outlined"
          />
          </form>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              search_patient();
            }}
            className={classes.button}
          >
            Search
          </Button>
          <Button
            variant="contained"
            color="secondary"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setPanel("");
              setId("");
              setMobile("");
              setName("");
              setAge("");
              setCNIC("")
              setPassport("")
              setEntitlement_Name("")
              setEntitlement_Id("")
              setEntitlement_Amount("")
              setData([])
              setDiscount(0)
              setTotal_cost(0)
              setTotalSpecialDiscount(0)
              setTotalNetCost(0)
              setBalance_Payment(0)
              setReceived_Payment(0)
              setSelected_Test_Code_List([])
              setCC([])
              
            
            }}
            className={classes.button}
          >
            Clear
          </Button>
          <div  style={{
            marginLeft:'5em',
            marginTop: "1em"
          }}>
          <Button 
          variant="contained"
          color="primary"
         style={{
           height:'100%'
         }}
         onClick={()=>{
           Call_Patient_History()
         }}
          >Patient History</Button>
          </div>
        </div>
        <div>
         
          <TextField
            required
            id="outlined-required"
            label="Patient Age"
            value={age}
            
            variant="outlined"
          />
            <TextField
            required
            id="outlined-required"
            label="CNIC"
            value={CNIC}
            
            variant="outlined"
          />
            <TextField
            required
            id="outlined-required"
            label="Passport"
            value={passport}
            
            variant="outlined"
          />
             <TextField
            required
            id="outlined-required"
            label="Entitlement Name"
            value={Entitlement_Name}
            
            variant="outlined"
          />
          
            
 
        </div>
        
        {
          localStorage.getItem('org_branch_id')=='0'
          ?
          
          
          <>
          <div style={{
            display:'flex',
            flexDirection:"row",
            justifyContent:'space-between'
          }} >
          
           
          <Autocomplete
      id="country-select-demo"
      style={{ width: "15em", paddingRight:"1em"}}
      options={salesAgentList}
      disabled={Entitlement_Name.trim()!=""}
      classes={{
        option: classes.option
      }}
      autoHighlight
      onChange={(event, newValue) => {
        if(newValue!=null){
          setSalesAgent(newValue.Agent_ID)
        }else{
          setSalesAgent("Select")
        }
      }}
      getOptionLabel={(option) => option.Agent_Name}
      renderOption={(option) => <React.Fragment>{option.Agent_Name}</React.Fragment>}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a Sales Agent"
          variant="outlined"
          style={{
            width:"15em"
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password" // disable autocomplete and autofill
          }}
        />
      )}
    />
  
       
          
          {
            panel!='' ?
            <>
            <TextField 
            required
            id="outlined-required"
            label="Default Panel"
            value={panel}
            
            variant="outlined"
            />
            
          <Button 
          variant="contained"
          color="primary"
         style={{
           height:'100%',
         marginTop: "1em"
         }}
         onClick={()=>{
           setPanel("")
           handleChange({
            target:{
              value:"Normal"
            }
          })
         }}
         >Change Panel</Button>
            </>
            :
            <Autocomplete
            id="country-select"
            disabled={Entitlement_Name.trim()!=""}
            options={panelList}
            classes={{
              option: classes.option
            }}
            autoHighlight
             onChange={(event, newValue) => {
              if(newValue!=null){

                handleChange({
                  target:{
                    value:newValue.Panel_Code
                  }
                })
                
              }else{
                handleChange({
                  target:{
                    value:"Normal"
                  }
                })
              }
            }}
            getOptionLabel={(option) => option.Panel_Title}
            style={{
              width:"20em"
            }}
            renderOption={(option) => <React.Fragment>{option.Panel_Title}</React.Fragment>}
            
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a Panel"
                variant="outlined"
                style={{
                  width:"20em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
          }
       <Autocomplete
            id="Ref_By_Doctor"
  
            options={DoctorList}
            classes={{
              option: classes.option
            }}
            autoHighlight
            getOptionLabel={(option) => option.DoctorName+""}
            style={{
              width:"15em",
              marginRight:'1em'
            }}
            renderOption={(option) => <React.Fragment>{option.DoctorName}</React.Fragment>}
            onChange={(event, newValue) => {

              if(newValue!=null){
              
               setRef_By_Dr(newValue.DoctorID)
               setRef_No('')
              }else{
                setRef_By_Dr('')
                setRef_No('')
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Ref By Doctor"
                variant="outlined"
                style={{
                  width:"15em"
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
    
      </div>
     {
       Ref_By_Dr=="" || Ref_By_Dr==430
       ?
       null
       : 
       <div
       style={{
         float:'right'
       }}
       >
          <TextField 
             required
             style={{
               width:"15em"
             }}
             value={Ref_No}
             onChange={(e)=>{
               setRef_No(e.target.value)
             }}
             id="outlined-required"
             label="Reference No"
             variant="outlined"
             />
       </div>
     }
        </>
          :
          <div>
          <div  style={{
            textAlign:'center',

          }}>
          <h3>Panel Selection</h3>
          </div>
        <div style={{
          textAlign:'center'
        }}>
      
         <FormControl variant="filled" className={classes.formControl}  style={{width:"30em"}} >
        <InputLabel id="demo-simple-select-filled-label">Panel Selection</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={selectedValue}
          onChange={(e)=>{
           handleChange(e)
          }}
        >
           <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"AR-01042"}>PIA</MenuItem>
          <MenuItem value={"AR-01037"}>FlyDubai</MenuItem>
          <MenuItem value={"AR-01036"}>Emirates</MenuItem>
          <MenuItem value={"AR-01033"}>Kingdom of Saudi Arabia</MenuItem>
          <MenuItem value={"AR-01044"}>Air Arabia</MenuItem>
          <MenuItem value={"Normal"}>Normal Patient</MenuItem>
        </Select>
      </FormControl>
    </div>
       
        </div>
        }
        {
  selectedValue=="AR-01059" || airline=='Qatar Airways'
  ?
  <div style={{display:'flex',flexDirection:'column', textAlign:"center", width:"40%", margin:"auto"}}>
   
  <b>Consent Form for Qatar (Compulsory)</b>
  <br />
  
<Button
  variant="contained"
  color="primary"
  className={classes.button}
>
       <input type="file" variant="contained"
  color="primary"
  onChange={(e)=>{handleFileRead(e)}}
  id="img" name="img" accept="image/*"
  className={classes.button} 
  
  aria-label="Attach PDF"
/>
</Button>
{
  consentForm!=""?
  <img id="consentFormPreview" src={consentForm} alt="Consent Form" width="250px" height="300px" style={{
    margin:"auto"
  }}/>
  :null
}
</div>
: null
}
 
      {
       ( NormalCase || otherPanelCase )?
        null
        :
        <>
        <div style={{ display: "-webkit-inline-box", width: "100%" }}>
        <TextField
          required
          id="outlined-required"
          label="Ticket No"
          disabled={NormalCase || otherPanelCase}
          value={ticket_no}
          onChange={(e) => {
            setTicket_No(e.target.value);
          }}
          variant="outlined"
        />

        <TextField
          required
          id="outlined-required"
          label="Flight No"
          value={flight_no}
          
          disabled={NormalCase || otherPanelCase}
          onChange={(e) => {
            setFlight_No(e.target.value);
          }}
          variant="outlined"
        />
         <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        
        disabled={NormalCase || otherPanelCase}
        label="Flight Date"
        value={flight_date}
        onChange={(e)=>{
          setFlight_Date(e)
        }}
        KeyboardButtonProps={{
          'aria-label': 'Flight Date',
        }}
      />
      <KeyboardTimePicker
    label="Flight Time"
    placeholder="08:00"
    mask="__:__"
    format="HH:mm"
    disabled={NormalCase || otherPanelCase}
    value={flight_time}
    onChange={date => setFlight_time(date)}
  />
      </MuiPickersUtilsProvider>
      {
         selectedValue=='AR-01033' ?
         <FormControl variant="filled" className={classes.formControl}  style={{width:"15em"}} >
      <InputLabel id="demo-simple-select-filled-label">AirLine</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={airline}
        onChange={(e)=>{
          setAirline(e.target.value)

        }}
      >
         <MenuItem disabled={true} value={"Select"}>Select</MenuItem>
          <MenuItem value={"Air Arabia"}>Air Arabia</MenuItem>
          <MenuItem value={"Emirates"}>Emirates</MenuItem>
          <MenuItem value={"FlyDubai"}>FlyDubai</MenuItem>
          <MenuItem value={"Gulf Air"}>Gulf Air</MenuItem>
          <MenuItem value={"PIA"}>PIA</MenuItem>
          <MenuItem value={"Salam Air"}>Salam Air</MenuItem>
          <MenuItem value={"Saudi Airline"}>Saudi Airline</MenuItem>
          <MenuItem value={"Turkish Air"}>Turkish Air</MenuItem>
          <MenuItem value={"AirBlue"}>AirBlue</MenuItem>
          <MenuItem value={"Flynas"}>Flynas</MenuItem>
          <MenuItem value={"Kuwait Airways"}>Kuwait Airways</MenuItem>
          <MenuItem value={"Qatar Airways"}>Qatar Airways</MenuItem>
          <MenuItem value={"Oman Air"}>Oman Air</MenuItem>
          <MenuItem value={"Jazeera Airways"}>Jazeera Airways</MenuItem>
      </Select>
    </FormControl>
    :
    <TextField
    required
    id="outlined-required"
    label="AirLine"
    
    disabled={NormalCase || otherPanelCase}
    value={airline}
    variant="outlined"
  /> 
       }
       
      </div>
      <div>
      <TextField
          required
          id="outlined-required"
          label="Airport"
          
          disabled={NormalCase || otherPanelCase}
          value={airport}
          onChange={(e) => {
            setAirport(e.target.value);
          }}
          variant="outlined"
        />
      <TextField
          required
          id="outlined-required"
          label="Destination"
          disabled={NormalCase || otherPanelCase}
          value={destination}
          onChange={(e) => {
            setDestination(e.target.value);
          }}
          variant="outlined"
        />
          <TextField
          required
          id="outlined-required"
          label="Country"
          style={{width:"30em"}}
          disabled={NormalCase || otherPanelCase}
          value={country}
          variant="outlined"
        />
        
        </div>
        </>
      }
      <div>
      <Autocomplete
            id="Lab Test Selection"
  
            options={TestList}
            classes={{
              option: classes.option
            }}
            autoHighlight
            
            getOptionLabel={(option) => option.Test_Code+" , "+option.Test_Name}
            style={{
              width:"35em",
              marginRight:'1em',
              marginTop:"1em"
            }}
            renderOption={(option) => <React.Fragment>{option.Test_Code+" , "+option.Test_Name}</React.Fragment>}
            onChange={(event, newValue) => {

              if(newValue!=null){
                if(Selected_Test_Code_List.includes(newValue.Test_Code)){
                  alert("Already Test Added!!")
                  return
                }
                var panel_id=selectedValue
                if(panel_id=="Normal"){
                  panel_id=""
                }
                var obj={
                  Branch_ID:localStorage.getItem('branch_id'),
                  Panel_ID:panel_id,
                  Test_ID:newValue.Test_ID
                }
                var vSearchStr=JSON.stringify(obj)
            var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceCallsPriceTest?vQuery=" + vSearchStr
              
                  fetch(url).then((res)=>res.json()).then(async(response)=>{
                   var Apply_The_Discount_Price=0
                    if(Entitlement_Name.trim()!=""){
                      setDiscount(100)
                    setTempDiscount(100)
                    
                    Apply_The_Discount_Price=total_cost+response[0].Test_Price
                    }else{
                      setDiscount(0)
                      setDiscountPrice(0)
                      setTempDiscount(0)
                      Apply_The_Discount_Price=0
                    }
                    if(Entitlement_Name.trim()!=""){
                      var sum=parseInt(response[0].Test_Price)+ total_cost
                      if(sum>parseInt(Entitlement_Amount)){
                        alert("Entitlement Allocated Amount Reached!!")
                        return
                      }

                    }
                    var date=new Date().ddmmyyy()
                    var time=formatAMPM(new Date())
                    
                    var obj1=
                      {
                        Test_ID:response[0].Test_ID,
                        code: response[0].Test_Code, 
                        title:response[0].Test_Name, 
                        description:date+" AT "+time, 
                        cost:response[0].Test_Price,
                        discount_percent:0, 
                        discount_value:0,
                        special_discount:0,
                        net_cost:response[0].Test_Price
                      }
                      setSelected_Test_Code_List([...Selected_Test_Code_List,response[0].Test_Code])
                    var all_test=[...data,obj1]
                    var temp_net_cost=0
                  var payable=0
                  var counter=0
                  var index=0
                  var calculating_discount=0
                  var temp_discount_value=0
                  var temp_total_cost=0
                  var the_total_cost=total_cost+response[0].Test_Price
                
                var hold=await all_test.map((row)=>{
                var vP=((row.cost-row.discount_value)/(the_total_cost-total_discount_value))*100
                console.log("Percentage of Row = "+vP)
                 var allocate_the_discount=Math.round((vP*Apply_The_Discount_Price)/100)
                  if(all_test.length==(index+1)){
                   allocate_the_discount=Apply_The_Discount_Price-calculating_discount
                 }
                 calculating_discount=calculating_discount+allocate_the_discount
                  if(parseInt(row.cost) - parseInt(row.discount_value)==0){
                  return row
                 }
                  temp_net_cost=(parseInt(row.cost) - parseInt(row.discount_value)) -  allocate_the_discount
                 console.log("Net Cost = "+temp_net_cost)
                  index=index+1
                  if(temp_net_cost<0){
                    counter=counter+1
                    return row

                  }
                  
                  var net_final_cost=temp_net_cost
                  payable=payable+net_final_cost
                  temp_discount_value= row.discount_value+temp_discount_value
                  temp_total_cost=temp_total_cost+row.cost
                 var obj={
                  Test_ID:row.Test_ID,
                    code: row.code, 
                  title:row.title, 
                  description:row.description, 
                  cost:row.cost,
                  discount_percent:row.discount_percent, 
                  discount_value:row.discount_value,
                  special_discount:allocate_the_discount,
                  net_cost:net_final_cost
                 }

                 return obj
                 })
                 
                 Promise.all(hold).then((values)=>{
                  if(counter==0){
                    setData(values)
                    setTotalSpecialDiscount(Apply_The_Discount_Price)
                    setTotalNetCost(payable)
                    var temp=parseInt((Apply_The_Discount_Price/(parseInt(temp_total_cost)-parseInt(temp_discount_value)))*100)
                    if(Apply_The_Discount_Price==0){
                      temp=0
                    }
                    setDiscount(temp)
                    setCC([])
                    setTotal_cost(temp_total_cost)
                    setTotalDiscountValue(temp_discount_value)
                    setBalance_Payment(payable)
                    setReceived_Payment(0)
                    document.getElementById("Lab Test Selection").value=""
                    document.getElementById("Lab Test Selection").focus()
                    
                  }
                 })
                    
                  })
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select a Test"
                variant="outlined"
                style={{
                  width:"35em"
                }}
                id={"Test_Selector"}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
        </div>
{/* <div style={{
  position:"absolute",
  marginTop:"15em",
  width:"100%"
}}>
<Grid container >
          <Grid xs={4} sm={4} md={4} lg={4}></Grid>
          <Grid xs={4} sm={4} md={4} lg={4}></Grid>
          <Grid xs={4} sm={4} md={4} lg={4} style={{
            
          }}>
          <Button
                variant="contained"
                color="primary"
                disabled={saveInvoice}
                style={{
                  textAlign:'center',
                  padding:"1em",
                  fontSize:'1em'
                }}
                className={classes.button}
                onClick={()=>{
                  setSaveInvoice(true)
                  if(CNIC.trim()==''){
                    alert('Failed, CNIC is compulsory for COVID-19')

                  setSaveInvoice(false)
                    return
                  }
                  if(total_bill==0){
                    alert("Invalid Amount")

                  setSaveInvoice(false)
                    return
                  }
                  if(name.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
                  if(id.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
                  if(mobile.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
               
                  
                  if(!NormalCase && (selectedValue=='AR-01033' || selectedValue=='AR-01042' || selectedValue=='AR-01037' || selectedValue=='AR-01036' ||  selectedValue=='AR-01044')){
                    if(CNIC.trim()=="" || passport.trim()==""){
                      alert('Failed, CNIC & Passport is compulsory for Passengers')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time==0){
                      alert('Kindly Select Flight Time!!')

                  setSaveInvoice(false)
                      return
              
                    }
                    if(airline.trim()==''){
                      alert('Invalid AirLine !')

                  setSaveInvoice(false)
                      return
                    }
                    if(ticket_no.trim()==''){
                      alert('Invalid Ticket Number!')

                  setSaveInvoice(false)
                      return
                    } 
                    if(flight_date.getFullYear()=='1900'){
                      alert('Invalid Flight Date!')

                  setSaveInvoice(false)
                      return
              
                    }
                    if(flight_no.trim()==''){
                      alert('Invalid Flight Number!')

                  setSaveInvoice(false)
                      return
                    } 
                    if(flight_date=='Invalid Date'){
                      alert('Invalid Flight Date!')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time=='Invalid Date'){
                      alert('Invalid Flight Time!')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time==''){
                      alert('Invalid Flight Time!')

                  setSaveInvoice(false)
                      return
                    }
                    var current_date=new Date()
                    const diffTime = Math.abs(flight_date - current_date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    if(diffDays>72){
                      alert('Flight Date is more than 72 Hours!')

                  setSaveInvoice(false)
                      return
                    }
                  
                    if(airport.trim()==''){
                      alert('Invalid Airport!')

                  setSaveInvoice(false)
                      return
                    }
                    if(destination.trim()==''){
                      alert('Invalid Destination!')

                  setSaveInvoice(false)
                      return
                    }
                    flight_date.setHours(15)
                    var user_id=localStorage.getItem('user_id')
                    var branch_id=localStorage.getItem('branch_id')
                   
                    var salesAgent_ID=""
                    var salesAgent_Name=""
                    if(salesAgent!="Select"){
                      salesAgent_ID=salesAgent
                      salesAgent_Name=salesAgentNameList[salesAgent_ID]
                    }

                    var SearchFormData = {
                      Branch_ID:branch_id,
                      User_ID:user_id,
                      Ref_No:"",
                      Panel_ID:selectedValue,
                      Patient_ID:id,
                      Ticket_No:ticket_no,
                      Flight_No:flight_no,
                      Flight_Date:flight_date.toISOString(),
                      Flight_Time:formatAMPM(flight_time),
                      AirLine:airline,
                      Airport:airport,
                      Destination:destination,
                      Discount:"0",
                      Received:total_bill+"",
                      Agent_ID:salesAgent_ID+"",
                      Agent_Name:salesAgent_Name
                      }
                      console.log(SearchFormData)
                        var vSearchStr = JSON.stringify(SearchFormData);
                     var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceCreateCovid?vQuery=" + vSearchStr+"&vUID=180820006&vRole=Admin"
                    
                        fetch(url).then((res)=>res.json()).then((response)=>{
                          console.log(response)
                          if(response[0].Invoice_Status=='Successful'){
                            alert("Invoice : MPL-"+response[0].Invoice_No+" Successfully Created")
                            localStorage.setItem('patient_mobile',"")
                          localStorage.setItem("patient_name","")
                        
                            window.location.href="https://booking.mpl-labs.pk/Invoices"
                   
                          }else{
                            alert("Sorry Failed !!")

                  setSaveInvoice(false)
                          }
                        })

                  }else{
                    var user_id=localStorage.getItem('user_id')
                    var branch_id=localStorage.getItem('branch_id')
                    var selectedpanel=""
                   if(NormalCase){
                     selectedpanel=""
                   }else{
                     selectedpanel=selectedValue
                   }
                   var salesAgent_ID=""
                   var salesAgent_Name=""
                   if(salesAgent!="Select"){
                     salesAgent_ID=salesAgent
                     salesAgent_Name=salesAgentNameList[salesAgent_ID]
                   }
                   
                    var SearchFormData = {
                      Branch_ID:branch_id,
                      User_ID:user_id,
                      Ref_No:"",
                      Panel_ID:selectedpanel,
                      Patient_ID:id,
                      Ticket_No:"",
                      Airport:"",
                      Flight_No:"",
                      Flight_Date:"",
                      Flight_Time:"",
                      AirLine:"",
                      Destination:"",
                      Discount:"0",
                      Received:total_bill+"",
                      Agent_ID:salesAgent_ID+"",
                      Agent_Name:salesAgent_Name
                      }
                      console.log(SearchFormData)
                        var vSearchStr = JSON.stringify(SearchFormData);
                     var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceCreateCovid?vQuery=" + vSearchStr+"&vUID=180820006&vRole=Admin"
                    
                        fetch(url).then((res)=>res.json()).then((response)=>{
                          console.log(response)
                          if(response[0].Invoice_Status=='Successful'){
                            alert("Invoice : MPL-"+response[0].Invoice_No+" Successfully Created")
                            localStorage.setItem('patient_mobile',"")
                            localStorage.setItem("patient_name","")
                         
                            window.location.href="https://booking.mpl-labs.pk/Invoices"
                   
                          }else{
                            alert("Sorry Failed !!")

                  setSaveInvoice(false)
                          }
                        })
                  }
                
              
                }}
                >Save Invoice</Button>
                </Grid>
            </Grid>
  </div> */}
               {/* <div>
          <Autocomplete
            id="combo-box-demo"
            options={lab_tests}
            getOptionLabel={(option) => option.Test_Code.toUpperCase()}
            onSelect={(e) => {
              var test = e.target.value.toUpperCase();
              if (test.trim() != "") {
                var result = selectOption[test];
                if (result != undefined) {
                  setState((prevState) => {
                    const data = [...prevState.data];
                    if (!data.includes(result)) {
                      data.push(result);
                      var price = parseInt(result.net_cost) + total_bill;
                      setTotal_Bill(price);
                      return { ...prevState, data };
                    }
                    return { ...prevState, data };
                  });
                }
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "23em" }}
                label="Services"
                variant="outlined"
              />
            )}
          />
        </div> */}
        <div>
          <Grid container>
            <Grid xs={12} sm={12} md={12} lg={12}>
            <MaterialTable
      title="Services"
      columns={columns}
      data={data}
      style={{
        marginLeft:10
      }}
      maxBodyHeight={"50em"}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(async() => {
              const dataUpdate = [...data];
              const index = oldData.tableData.id;
            
              if(newData.discount_percent!=oldData.discount_percent){
                if(newData.discount_percent>100){
                  
                }else{
                  var dc_value=(newData.cost/100)* parseInt(newData.discount_percent)
                 
                  var net_cost=(newData.cost-dc_value) - parseInt(newData.special_discount)
                 
                  if(net_cost<0){
                    alert("More Disocunt can't applied!")
                    
                    resolve();
                    return
                  }
                  newData.discount_value=dc_value
                  newData.net_cost=net_cost
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                }
              }

              var cost=0
              var discount_value=0
              var sp_disc=0
              var net_cost=0
              var temp=await dataUpdate.map((item)=>{
                cost=cost+item.cost
                discount_value=discount_value+item.discount_value
                sp_disc=sp_disc+item.special_discount
                return net_cost=net_cost+item.net_cost
              })
              Promise.all(temp).then(()=>{
                setTotal_cost(cost)
                setTotalDiscountValue(discount_value)
                setTotalSpecialDiscount(sp_disc)
                setTotalNetCost(net_cost)
                setCC([])
                setBalance_Payment(net_cost)
                setReceived_Payment(0)
              })
           

             

              resolve();
            }, 1000)
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(async() => {
              const dataDelete = [...data];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              var ind=Selected_Test_Code_List.indexOf(oldData.Test_Code)
              Selected_Test_Code_List.splice(ind,1)

              if(data.length==0){
                setTotalNetCost(0)
                setTotalSpecialDiscount(0)
                setTotal_cost(0)
                setTotalDiscountValue(0)
                setDiscount(0)
                setReceived_Payment(0)
                setBalance_Payment(0)
                setCC([])
              }else{
                var all_test=[...dataDelete]
              
                var temp_net_cost=0
              var payable=0
              var counter=0
              var i=0
              var calculating_discount=0
              var temp_discount_value=0
              var temp_total_cost=0
              var the_total_cost=total_cost-oldData.cost
              var Apply_The_Discount_Price=0
              if(Entitlement_Name.trim()!=""){
                Apply_The_Discount_Price=the_total_cost
              }else{
                Apply_The_Discount_Price=0 
              }
              var the_total_discount_price=total_discount_value-oldData.discount_value
            
            var hold=await all_test.map((row)=>{
            var vP=((row.cost-row.discount_value)/(the_total_cost-the_total_discount_price))*100
            console.log("Percentage of Row = "+vP)
             var allocate_the_discount=Math.round((vP*Apply_The_Discount_Price)/100)
              if(all_test.length==(i+1)){
               allocate_the_discount=Apply_The_Discount_Price-calculating_discount
             }
             calculating_discount=calculating_discount+allocate_the_discount
              if(parseInt(row.cost) - parseInt(row.discount_value)==0){
              return row
             }
              temp_net_cost=(parseInt(row.cost) - parseInt(row.discount_value)) -  allocate_the_discount
             console.log("Net Cost = "+temp_net_cost)
              i=i+1
              if(temp_net_cost<0){
                counter=counter+1
                return row

              }
              
              var net_final_cost=temp_net_cost
              payable=payable+net_final_cost
              temp_discount_value= row.discount_value+temp_discount_value
              temp_total_cost=temp_total_cost+row.cost
             var obj={
              Test_ID:row.Test_ID,
                code: row.code, 
              title:row.title, 
              description:row.description, 
              cost:row.cost,
              discount_percent:row.discount_percent, 
              discount_value:row.discount_value,
              special_discount:allocate_the_discount,
              net_cost:net_final_cost
             }

             return obj
             })
             
             Promise.all(hold).then((values)=>{
              if(counter==0){
                setData(values)
                setTotalSpecialDiscount(Apply_The_Discount_Price)
                setTotalNetCost(payable)
                var temp=parseInt((Apply_The_Discount_Price/(parseInt(temp_total_cost)-parseInt(temp_discount_value)))*100)
                if(Apply_The_Discount_Price==0){
                  temp=0
                }
                setDiscount(temp)
                setCC([])
                setTotal_cost(temp_total_cost)
                setTotalDiscountValue(temp_discount_value)
                setBalance_Payment(payable)
                setReceived_Payment(0)
              }
             })
              }

              setData([...dataDelete]);

              resolve();
            }, 1000)
          }),
      }}
    />
            </Grid>
            
          </Grid>
          
          </div>
          <div>
          <Grid container style={{
            marginTop:10,
            marginLeft:10
          }}>
            
            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display:'flex',
                flexDirection:'column'
              }
            }>
            <b>Total Cost</b>
            <TextField
            required
            id="outlined-required"
            label=""
            value={total_cost}
            style={{
              borderRadius:5
            }}
            variant="outlined"
          />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3}  style={
              {
                display:'flex',
                flexDirection:'column'
              }
            }>
            <b>Allocated Discount Value</b>
            <TextField
            required
            id="outlined-required"
            style={{
              borderRadius:5
            }}
            label=""
            value={total_discount_value}
            variant="outlined"
          />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3}  style={
              {
                display:'flex',
                flexDirection:'column'
              }
            }>
            <b>Allocated Special Discount</b>
            <TextField
            required
            id="outlined-required"
            label=""
            style={{
              borderRadius:5
            }}
            value={total_special_discount}
            variant="outlined"
          />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={
              {
                display:'flex',
                flexDirection:'column'
              }
            }>
              <b>Net Payable Value</b>
            <TextField
            required
            id="outlined-required"
            label=""
            
            style={{
              borderRadius:5,
            }}
            value={total_net_cost}
            variant="outlined"
          />
            </Grid>
            </Grid>  
          
          </div>
          <Grid container style={{
            marginLeft:10
          }}>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
              display:"flex",
              flexDirection:"column"
            }}>
              <b>Patient Comments</b>
            <TextareaAutosize
      rowsMax={4}
      style={{
        padding:'0.5em',
        height:'5em',
        marginLeft:"0.6em"
      }}
      value={Comments_for_Patient}
      onChange={(e)=>{
        setComments_for_Patient(e.target.value)
      }}
      placeholder="Patient Comments"
    />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
              display:"flex",
              flexDirection:"column"
            }}>
            <b>Remarks</b>
            <TextareaAutosize
      rowsMax={4}
      style={{
        padding:'0.5em',
        height:'5em',
        marginLeft:"0.6em"
      }}
      value={Invoice_Remarks}
      onChange={(e)=>{
        setInvoiceRemarks(e.target.value)
      }}
      placeholder="Invoice Remarks"
    />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} >
            
        <FormGroup style={{
          marginLeft:"5em"
        }}>
          <b>Payment Method</b>
          <FormControlLabel
            control={<Checkbox checked={cash} onChange={(e)=>{
              setCash(e.target.checked)
              if(e.target.checked){
                setCreditCard(false)
                setDD(false)
                setCheque(false)
              }
            }}  />}
            label="CASH"
          />
          <FormControlLabel
            control={<Checkbox checked={credit_card} onChange={(e)=>{
              setCreditCard(e.target.checked)
              if(e.target.checked){
                setCash(false)
                setDD(false)
                setCheque(false)
              }
            }}  />}
            label="Credit Card"
          />
          <FormControlLabel
            control={<Checkbox checked={dd} onChange={(e)=>{
              setDD(e.target.checked)
              if(e.target.checked){
                setCash(false)
                setCreditCard(false)
                setCheque(false)
              }
            }}  />}
            label="Demand Draft"
          />
           <FormControlLabel
            control={<Checkbox checked={cheque} onChange={(e)=>{
              setCheque(e.target.checked)
              if(e.target.checked){
                setCash(false)
                setDD(false)
                setCreditCard(false)
              }
            }}  />}
            label="Cheque"
          />
        </FormGroup>
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
            display:'flex',
            flexDirection:'column'
            }}>
            
             <b>Discount % (line items)</b>
             <span style={{
                display:"flex",
                flexDirection:"row",
                marginRight:"0.5em"
              }}>
             <TextField
            required
            id="outlined-required"
            label=""
            disabled={Entitlement_Name.trim()!=""}
            style={{
              borderRadius:5,
            }}
           
            type="number"
            onChange={(e)=>{
              setTempDiscount(parseInt(e.target.value))
              setDiscountPrice(0)
            }}
            value={TempDiscount}
            variant="outlined"
          />
          <b style={{
           marginTop:"10%"
          }}>%</b>
            <TextField
            required
            id="outlined-required"
            label=""
            style={{
              borderRadius:5,
            }}
            disabled={Entitlement_Name.trim()!=""}
            type="number"
            onChange={(e)=>{
              
             setDiscountPrice(parseInt(e.target.value))
             setTempDiscount(0)
            }}
            value={DiscountPrice}
            variant="outlined"
          />
          <b style={{
           marginTop:"10%"
          }}>Rs.</b>
          </span>

             <span style={{
               display:"flex",
               flexDirection:"row"
               
             }}>
                <TextField
            required
            id="outlined-required"
            label=""
            title="Applied Discount"
            label="Applied Discount"
            
            style={{
              borderRadius:5,
              fontSize:10,
              fontWeight:'bold'
            }}
          value={Discount+" %"}
            variant="outlined"
          />
         
          
          <Button 
           variant="contained"
           color="primary"
          style={{
            height:'50%',
            marginTop:"1em"
                         }}
          onClick={
           async ()=>{
             if(Entitlement_Name!=''){
               alert("Entitlement gives 100% Discount!!")
               setDiscount(100)
               setTempDiscount(100)
               
               return
             }
             
             var dis=parseInt(DiscountPrice)
              if(TempDiscount!=0){
                dis=parseInt(((total_cost-total_discount_value)/100)*TempDiscount)
                setDiscountPrice(dis)
              }
              if(DiscountPrice!=0){
               if(DiscountPrice>(total_cost-total_discount_value)){
                 alert("Discount can't be more than Payable Amount")
                 return
               }

              }

               
                  // Each Test Price Calculation
                 
                  var temp_net_cost=0
                  var payable=0
                  var counter=0
                  var index=0
                  var calculating_discount=0
                  console.log(data)
                var hold=await data.map((row)=>{
                var vP=((row.cost-row.discount_value)/(total_cost-total_discount_value))*100
                 var allocate_the_discount=Math.round((vP*dis)/100)
                 console.log("Allocate Discount = "+allocate_the_discount)
                 if(data.length==(index+1)){
                   allocate_the_discount=dis-calculating_discount
                 }
                 calculating_discount=calculating_discount+allocate_the_discount
                 console.log("Calculated Discount = "+calculating_discount)
                 console.log("Test Name = "+row.title)
                 console.log("Original Cost  = "+row.cost)
                 console.log("Discount Value = "+row.discount_value)
                 if(parseInt(row.cost) - parseInt(row.discount_value)==0){
                  return row
                 }
                  temp_net_cost=(parseInt(row.cost) - parseInt(row.discount_value)) -  allocate_the_discount
                 
                  console.log("Net Cost = "+temp_net_cost)
                  index=index+1
                  if(temp_net_cost<0){
                    counter=counter+1
                    return row

                  }
                  
                  var net_final_cost=temp_net_cost
                  payable=payable+net_final_cost
                 var obj={
                  Test_ID:row.Test_ID,
                    code: row.code, 
                  title:row.title, 
                  description:row.description, 
                  cost:row.cost,
                  discount_percent:row.discount_percent, 
                  discount_value:row.discount_value,
                  special_discount:allocate_the_discount,
                  net_cost:net_final_cost
                 }

                 return obj
                 })
                 
                 Promise.all(hold).then((values)=>{
                  if(counter==0){
                    setData(values)
                    setTotalSpecialDiscount(dis)
                    setTotalNetCost(payable)
                    setDiscount(parseInt((dis/(total_cost-total_discount_value))*100))
                    setCC([])
                    setBalance_Payment(payable)
                    setReceived_Payment(0)
                  }
                 })
                  
              

                
                
              
            }
          }>Apply</Button>
          </span>
     
            </Grid>

           
          </Grid>
          <div style={{
            marginLeft:10
          }}>  <h2>Transactions Posting</h2></div>
          <div style={{
            marginLeft:10,
            display:"flex",
            flexDirection:"row"
          }}>
            
            <TextField
            required
            id="outlined-required"
            label="Amount"
            style={{
              borderRadius:5,
            }}
            value={cc_amount}
            type="number"
            onChange={(e)=>{
              setCCAmount(e.target.value)
            }}
            variant="outlined"
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
         <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        id="date-picker-inline"
        label="Date"
        value={cc_date}
        onChange={(e)=>{
          setCCDate(e)
        }}
        KeyboardButtonProps={{
          'aria-label': 'Date',
        }}
      />
      </MuiPickersUtilsProvider>
      {
        (cheque || credit_card || dd) ?
        <TextField
            required
            id="outlined-required"
            label="Cheque / Transaction #"
            style={{
              borderRadius:5,
            }}
            value={cc_num}
            onChange={(e)=>{
              setCCNum(e.target.value)
            }}
            variant="outlined"
          />
          : null
      }
      <span style={{
        display:'flex',
        flexDirection:"column",
        marginTop:'-1em'
      }}>
        <b>Remarks</b>
           <TextareaAutosize
      rowsMax={4}
      style={{
        padding:'0.5em',
        height:'3em',
        marginLeft:"0.6em"
      }}
      value={cc_remarks}
      onChange={(e)=>{
        setCCRemarks(e.target.value)
      }}
      placeholder="Transaction Remarks"
    /></span>
    
      <Button
                variant="contained"
                color="primary"
                style={{
                  textAlign:'center',
                  fontSize:'1em',
                  height:"2em"
                }}
                onClick={()=>{
                  if(total_net_cost==0){
                    alert("Invoice Payable is Zero!!")
                    return
                  }
                  if(cc_amount==0 || cc_amount==""){
                    alert("Kindly Mention Received Amount !!")
                    return
                  }
                  var diff=(total_net_cost-cc_amount)-Received_Payment
                  if(diff<0){
                    alert("Payable Amount Reached!!")
                    return
                  }
                  var receive=parseInt(cc_amount)+parseInt(Received_Payment)
                  var bal=parseInt(total_net_cost)-receive
                  setReceived_Payment(receive)
                  setBalance_Payment(bal)
                  var obj={
                    date:cc_date.ddmmyyy(),
                    cc:cc_num,
                    amount:cc_amount,
                    remarks:cc_remarks,
                    mode:cash ? "CASH" : cheque ? "CHEQUE" : credit_card ? "CARD" : "DD",
                    shift_id:"",
                    user_id:localStorage.getItem("user_id"),
                    user_name:localStorage.getItem("name")
                  }
                  setCC([...CC,obj])
                  setCCAmount(0)
                  setCCRemarks("")
                  setCCNum("")
                }}
                className={classes.button}
                >Add</Button>
               
          </div>
    <div>
    <Grid container>
  
            <Grid xs={6} sm={6} md={6} lg={6}>
            <MaterialTable
      title="Cheque / Card"
      columns={cc_columns}
      data={CC}
      style={{
        marginLeft:10
      }}
      editable={{
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              const dataDelete = [...CC];
              const index = oldData.tableData.id;
              dataDelete.splice(index, 1);
              console.log(dataDelete)
              var receive=parseInt(Received_Payment)-parseInt(oldData.amount)
              var balance=parseInt(total_net_cost)-receive

              setReceived_Payment(receive)
              setBalance_Payment(balance)
              setCC([...dataDelete]);
              resolve();
            }, 1000)
          }),
      }}
    />
            </Grid>
            <Grid xs={3} sm={3} md={3} lg={3}></Grid>
            <Grid xs={3} sm={3} md={3} lg={3} style={{
              display:"flex",
              flexDirection:"column"
            }}>
            <TextField
            required
            id="outlined-required"
            label="RECEIVED"
            style={{
              borderRadius:5,
            }}
            value={Received_Payment}
            variant="outlined"
          />
            <TextField
            required
            id="outlined-required"
            label="BALANCE"
            style={{
              borderRadius:5,
            }}
            value={Balance_Payment}
            variant="outlined"
          />
            <Button
                variant="contained"
                color="primary"
                style={{
                  textAlign:'center',
                  width:"14.5em",
                  padding:"1em",
                  height:"4em",
                  marginLeft:"8px"
                }}
                onClick={async()=>{
                  setSaveInvoice(true)
                  if(CNIC.trim()=='' && Selected_Test_Code_List.includes("COVIDPCR")){
                    alert('Failed, CNIC is compulsory for COVID-19')

                  setSaveInvoice(false)
                    return
                  }
                
                  if(name.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
                  if(id.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
                  if(mobile.trim()==''){
                    alert("Kindly Select Patient")

                  setSaveInvoice(false)
                    return
                  }
                  if(data.length==0){
                    alert("Kindly Select Lab Test")
                    setSaveInvoice(false)
                      return
                  }
               
                  
                  if(!NormalCase && (selectedValue=='AR-01033' || selectedValue=='AR-01042' || selectedValue=='AR-01037' || selectedValue=='AR-01036' ||  selectedValue=='AR-01044' || selectedValue=='AR-01059' || selectedValue=='AR-01068' || selectedValue=='AR-01062' || selectedValue=='AR-01063' || selectedValue=='AR-01064' || selectedValue=='AR-01065' || selectedValue =='AR-01067' || selectedValue =='AR-01069')){
                    if(CNIC.trim()=="" || passport.trim()==""){
                      alert('Failed, CNIC & Passport is compulsory for Passengers')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time==0){
                      alert('Kindly Select Flight Time!!')

                  setSaveInvoice(false)
                      return
              
                    }
                    if(airline.trim()==''){
                      alert('Invalid AirLine !')

                  setSaveInvoice(false)
                      return
                    }
                    if(ticket_no.trim()==''){
                      alert('Invalid Ticket Number!')

                  setSaveInvoice(false)
                      return
                    } 
                    if(flight_date.getFullYear()=='1900'){
                      alert('Invalid Flight Date!')

                  setSaveInvoice(false)
                      return
              
                    }
                    if(flight_no.trim()==''){
                      alert('Invalid Flight Number!')

                  setSaveInvoice(false)
                      return
                    } 
                    if(flight_date=='Invalid Date'){
                      alert('Invalid Flight Date!')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time=='Invalid Date'){
                      alert('Invalid Flight Time!')

                  setSaveInvoice(false)
                      return
                    }
                    if(flight_time==''){
                      alert('Invalid Flight Time!')

                  setSaveInvoice(false)
                      return
                    }
                    var current_date=new Date()
                    const diffTime = Math.abs(flight_date - current_date);
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                    if(diffDays>72){
                      alert('Flight Date is more than 72 Hours!')

                  setSaveInvoice(false)
                      return
                    }
                  
                    if(airport.trim()==''){
                      alert('Invalid Airport!')

                  setSaveInvoice(false)
                      return
                    }
                    if(destination.trim()==''){
                      alert('Invalid Destination!')

                  setSaveInvoice(false)
                      return
                    }
                    flight_date.setHours(15)
                    var user_id=localStorage.getItem('user_id')
                    var branch_id=localStorage.getItem('branch_id')
                   
                    var salesAgent_ID=""
                    var salesAgent_Name=""
                    if(salesAgent!="Select"){
                      salesAgent_ID=salesAgent
                      salesAgent_Name=salesAgentNameList[salesAgent_ID]
                    }
                    var DoctorID=""
                    var DoctorName=""
 
                    if(Ref_By_Dr!=''){
                      DoctorID=Ref_By_Dr
                      DoctorName=DoctorNameList[DoctorID]
                    }else{
                      alert("Kindly Select Refer By")
                      setSaveInvoice(false)
                      return
                    }
                    if((selectedValue=='AR-01059' && consentForm=="" ) || (airline=='Qatar Airways' && consentForm=="")){
                      alert("Kindly Upload Consent Form!!")
                      setSaveInvoice(false)
                      return
                     }
                    var Arr_InV_teSt_ID=[]
                    var Arr_InV_CodE=[]
                    var Arr_InV_TiTLE=[]
                    var Arr_InV_DesCP=[]
                    var Arr_InV_LineS_naturE=[]
                    var Arr_InV_RatE=[]
                    var Arr_InV_DisC_P=[]
                    var Arr_InV_DisC_VaLuE=[]
                    var Arr_InV_DisC_SP=[]
                    var Arr_InV_AMOUNT=[]
                    var Arr_InV_REFUND=[]
                   var Arr_InV_CANCEL=[]
                   var Arr_InV_AuX_reCS_ID=[]
                   var Arr_InV_testS_GrouP_ID=[]
                   var Arr_InV_testS_GrouP_TiTLE=[]
                   var Total_Discount=0
                   var Total_SpecialDiscount=0
                   var Total_Amount=0

                   var invoice_table=await  data.map((item)=>{
                      Arr_InV_teSt_ID.push(item.Test_ID)
                      Arr_InV_CodE.push(item.code)
                      Arr_InV_TiTLE.push(item.title)
                      Arr_InV_DesCP.push(item.description)
                      Arr_InV_LineS_naturE.push("")
                      Arr_InV_RatE.push(item.cost+"")
                      Arr_InV_DisC_P.push(item.discount_percent+"")
                      Arr_InV_DisC_VaLuE.push(item.discount_value+"")
                      Arr_InV_DisC_SP.push(item.special_discount+"")
                      Arr_InV_AMOUNT.push(item.net_cost+"")
                      Arr_InV_REFUND.push(false)
                     Arr_InV_CANCEL.push(false)
                     Arr_InV_AuX_reCS_ID.push(0)
                     Arr_InV_testS_GrouP_ID.push(0)
                     Arr_InV_testS_GrouP_TiTLE.push("")
                     Total_Discount=Total_Discount+parseInt(item.discount_value)
                     Total_Amount=Total_Amount+parseInt(item.cost)

                      return item.code
                    })
                    var Arr_InV_REC_MODE=[]
                    var Arr_InV_REC_DATE=[]
                    var Arr_InV_REC_NO=[]
                    var Arr_InV_REC_ShiFT_ID=[]
                    var Arr_InV_REC_USER_ID=[]
                    var Arr_InV_REC_USER_NAME=[]
                    var Arr_InV_REC_REFUND=[]
                    var Arr_InV_REC_RemarKS=[]
                    var Arr_InV_AGainst_PreV_BalanCE=[]
                    var Arr_InV_REC_VaLuE=[]
                    
                   
                    var elements=[]
                    var curr_date=""

                   var temp1=await CC.map((item)=>{
                     elements=item.date.split("/")
                     curr_date=elements[1]+"/"+elements[0]+"/"+elements[2]
                    var transaction_date=new Date(curr_date)
                    transaction_date.setHours(15)
                     Arr_InV_REC_DATE.push(transaction_date.toISOString())
                     Arr_InV_REC_MODE.push(item.mode)
                     Arr_InV_REC_NO.push(item.cc)
                     Arr_InV_REC_ShiFT_ID.push("")
                     Arr_InV_REC_USER_ID.push(item.user_id)
                     Arr_InV_REC_USER_NAME.push(item.user_name)
                     Arr_InV_REC_REFUND.push(false)
                     Arr_InV_REC_RemarKS.push(item.remarks)
                     Arr_InV_AGainst_PreV_BalanCE.push(false)
                     Arr_InV_REC_VaLuE.push(item.amount+"")
                     return item.mode
                    })
                   Promise.all(invoice_table,temp1).then((values)=>{
                    var invoice={
                      Branch_ID:branch_id,
                      User_ID:user_id,
                      Ref_No:Ref_No,
                      Panel_ID:selectedValue,
                      Ref_By_Doctor_ID:DoctorID,
                      Ref_By_Doctor_Name:DoctorName,
                      Patient_ID:id,
                      Ticket_No:ticket_no,
                      Flight_No:flight_no,
                      Flight_Date:flight_date.toISOString(),
                      Flight_Time:formatAMPM(flight_time),
                      AirLine:airline,
                      Airport:airport,
                      Destination:destination,
                      SpecDiscount:DiscountPrice,
                      Discount:Total_Discount,
                      Amount:Total_Amount,
                      Entitlement_ID:Entitlement_Id,
                      Entitlement_Name:Entitlement_Name,
                      Received:Received_Payment,
                      Agent_ID:salesAgent_ID+"",
                      Comments_for_Patient:Comments_for_Patient,
                      Invoice_Remarks:Invoice_Remarks,
                      Agent_Name:salesAgent_Name,
                      Arr_InV_teST_ID: Arr_InV_teSt_ID,
                      Arr_InV_CodE:    Arr_InV_CodE,
                      Arr_InV_TiTLE:   Arr_InV_TiTLE,
                      Arr_InV_DesCP:   Arr_InV_DesCP,
                      Arr_InV_LineS_naturE:Arr_InV_LineS_naturE,
                      Arr_InV_RatE:    Arr_InV_RatE,
                      Arr_InV_DisC_P:  Arr_InV_DisC_P,
                      Arr_InV_DisC_VaLuE:Arr_InV_DisC_VaLuE,
                      Arr_InV_DisC_SP: Arr_InV_DisC_SP,
                      Arr_InV_AMOUNT:  Arr_InV_AMOUNT,
                      Arr_InV_REC_MODE:Arr_InV_REC_MODE,
                      Arr_InV_REC_DATE:Arr_InV_REC_DATE,
                      Arr_InV_REC_NO:  Arr_InV_REC_NO,
                      Arr_InV_REC_ShiFT_ID: Arr_InV_REC_ShiFT_ID,
                      Arr_InV_REC_USER_ID: Arr_InV_REC_USER_ID,
                      Arr_InV_REC_USER_NAME: Arr_InV_REC_USER_NAME,
                      Arr_InV_REC_REFUND: Arr_InV_REC_REFUND,
                      Arr_InV_REC_RemarKS: Arr_InV_REC_RemarKS,
                      Arr_InV_AGainst_PreV_BalanCE:Arr_InV_AGainst_PreV_BalanCE,
                      Arr_InV_REC_VaLuE:Arr_InV_REC_VaLuE,
                    
                      Arr_InV_REFUND: Arr_InV_REFUND,
                      Arr_InV_CANCEL:Arr_InV_CANCEL,
                      Arr_InV_AuX_reCS_ID: Arr_InV_AuX_reCS_ID,
                      Arr_InV_testS_GrouP_ID:Arr_InV_testS_GrouP_ID,
                      Arr_InV_testS_GrouP_TiTLE:Arr_InV_testS_GrouP_TiTLE,
                      ConsentForm:consentForm.split('base64,')[1]

                    }
                    var vSearchStr = JSON.stringify(invoice);
                    if(Balance_Payment>0){
                     var res= window.confirm('Are you Sure? Balance of Invoice will be Due ?')
                     if(res){

                     }else{
                       return
                     }
                      
                    }
                    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceCreateNEW"
                    
                        fetch(url,{
                          method:"POST",
                        
                          body:vSearchStr
                        }).then((res)=>res.json()).then((response)=>{
                          console.log(response)
                          if(response[0].Invoice_Status=='Successful'){
                            alert("Invoice : MPL-"+response[0].Invoice_No+" Successfully Created")
                            localStorage.setItem('patient_mobile',"")
                          localStorage.setItem("patient_name","")
                        
                           window.location.href="https://radiology.fslabs.com.pk/Invoices"
                   
                          }else{
                            alert("Sorry Failed !!")

                  setSaveInvoice(false)
                          }
                        })

                   })
                    

                  }else{
                    var user_id=localStorage.getItem('user_id')
                    var branch_id=localStorage.getItem('branch_id')
                    var selectedpanel=""
                   if(NormalCase){
                     selectedpanel=""
                   }else{
                     selectedpanel=selectedValue
                   }
                   var salesAgent_ID=""
                   var salesAgent_Name=""
                   var DoctorID=""
                   var DoctorName=""

                   if(Ref_By_Dr!=''){
                     DoctorID=Ref_By_Dr
                     DoctorName=DoctorNameList[DoctorID]
                   }else{
                    alert("Kindly Select Refer By")
                    setSaveInvoice(false)
                    return
                  }
                   
                   if(salesAgent!="Select"){
                     salesAgent_ID=salesAgent
                     salesAgent_Name=salesAgentNameList[salesAgent_ID]
                   }
                   if(Balance_Payment>0){
                    var res= window.confirm('Are you Sure? Balance of Invoice will be Due ?')
                    if(res){

                    }else{
                      return
                    }
                     
                   }

                   var Arr_InV_teSt_ID=[]
                   var Arr_InV_CodE=[]
                   var Arr_InV_TiTLE=[]
                   var Arr_InV_DesCP=[]
                   var Arr_InV_LineS_naturE=[]
                   var Arr_InV_RatE=[]
                   var Arr_InV_DisC_P=[]
                   var Arr_InV_DisC_VaLuE=[]
                   var Arr_InV_DisC_SP=[]
                   var Arr_InV_AMOUNT=[]
                   var Arr_InV_REFUND=[]
                   var Arr_InV_CANCEL=[]
                   var Arr_InV_AuX_reCS_ID=[]
                   var Arr_InV_testS_GrouP_ID=[]
                   var Arr_InV_testS_GrouP_TiTLE=[]
                   var Total_Discount=0
                   var Total_SpecialDiscount=0
                   var Total_Amount=0
                  
                  var invoice_table=await data.map((item)=>{
                     Arr_InV_teSt_ID.push(item.Test_ID)
                     Arr_InV_CodE.push(item.code)
                     Arr_InV_TiTLE.push(item.title)
                     Arr_InV_DesCP.push(item.description)
                     Arr_InV_LineS_naturE.push("")
                     Arr_InV_RatE.push(item.cost+"")
                     Arr_InV_DisC_P.push(item.discount_percent+"")
                     Arr_InV_DisC_VaLuE.push(item.discount_value+"")
                     Arr_InV_DisC_SP.push(item.special_discount+"")
                     Arr_InV_AMOUNT.push(item.net_cost+"")
                     Arr_InV_REFUND.push(false)
                     Arr_InV_CANCEL.push(false)
                     Arr_InV_AuX_reCS_ID.push(0)
                     Arr_InV_testS_GrouP_ID.push(0)
                     Arr_InV_testS_GrouP_TiTLE.push("")
                     Total_Discount=Total_Discount+parseInt(item.discount_value)
                     Total_Amount=Total_Amount+parseInt(item.cost)
                    
                     return item.code
                   })
                   var Arr_InV_REC_MODE=[]
                   var Arr_InV_REC_DATE=[]
                   var Arr_InV_REC_NO=[]
                   var Arr_InV_REC_ShiFT_ID=[]
                   var Arr_InV_REC_USER_ID=[]
                   var Arr_InV_REC_USER_NAME=[]
                   var Arr_InV_REC_REFUND=[]
                   var Arr_InV_REC_RemarKS=[]
                   var Arr_InV_AGainst_PreV_BalanCE=[]
                   var Arr_InV_REC_VaLuE=[]
                  
                   var elements=[]
                   var curr_date=""

                  var temp1=await CC.map((item)=>{
                    elements=item.date.split("/")
                    curr_date=elements[1]+"/"+elements[0]+"/"+elements[2]
                   var transaction_date=new Date(curr_date)
                   transaction_date.setHours(15)
                    Arr_InV_REC_DATE.push(transaction_date.toISOString())
                    Arr_InV_REC_MODE.push(item.mode)
                    Arr_InV_REC_NO.push(item.cc)
                    Arr_InV_REC_ShiFT_ID.push("")
                    Arr_InV_REC_USER_ID.push(item.user_id)
                    Arr_InV_REC_USER_NAME.push(item.user_name)
                    Arr_InV_REC_REFUND.push(false)
                    Arr_InV_REC_RemarKS.push(item.remarks)
                    Arr_InV_AGainst_PreV_BalanCE.push(false)
                    Arr_InV_REC_VaLuE.push(item.amount+"")
                    return item.mode
                   })
                   
                    var SearchFormData = {
                      Branch_ID:branch_id,
                      User_ID:user_id,
                      Ref_No:Ref_No,
                      Panel_ID:selectedpanel,
                      Patient_ID:id,
                      Ticket_No:"",

                      Airport:"",
                      Flight_No:"",
                      Flight_Date:"",
                      Flight_Time:"",
                      AirLine:"",
                      Ref_By_Doctor_ID:DoctorID,
                      Ref_By_Doctor_Name:DoctorName,
                      Destination:"",
                      SpecDiscount:DiscountPrice,
                      Discount:Total_Discount,
                      Amount:Total_Amount,
                      Comments_for_Patient:Comments_for_Patient,
                      Invoice_Remarks:Invoice_Remarks,
                      Received:Received_Payment,
                      Entitlement_ID:Entitlement_Id,
                      Entitlement_Name:Entitlement_Name,
                      Agent_ID:salesAgent_ID+"",
                      Agent_Name:salesAgent_Name,
                      Arr_InV_teST_ID: Arr_InV_teSt_ID,
                      Arr_InV_CodE:    Arr_InV_CodE,
                      Arr_InV_TiTLE:   Arr_InV_TiTLE,
                      Arr_InV_DesCP:   Arr_InV_DesCP,
                      Arr_InV_LineS_naturE:Arr_InV_LineS_naturE,
                      Arr_InV_RatE:    Arr_InV_RatE,
                      Arr_InV_DisC_P:  Arr_InV_DisC_P,
                      Arr_InV_DisC_VaLuE:Arr_InV_DisC_VaLuE,
                      Arr_InV_DisC_SP: Arr_InV_DisC_SP,
                      Arr_InV_AMOUNT:  Arr_InV_AMOUNT,
                      Arr_InV_REC_MODE:Arr_InV_REC_MODE,
                      Arr_InV_REC_DATE:Arr_InV_REC_DATE,
                      Arr_InV_REC_NO:  Arr_InV_REC_NO,
                      Arr_InV_REC_ShiFT_ID: Arr_InV_REC_ShiFT_ID,
                      Arr_InV_REC_USER_ID: Arr_InV_REC_USER_ID,
                      Arr_InV_REC_USER_NAME: Arr_InV_REC_USER_NAME,
                      Arr_InV_REC_REFUND: Arr_InV_REC_REFUND,
                      Arr_InV_REC_RemarKS: Arr_InV_REC_RemarKS,
                      Arr_InV_AGainst_PreV_BalanCE:Arr_InV_AGainst_PreV_BalanCE,
                      Arr_InV_REC_VaLuE:Arr_InV_REC_VaLuE,

                      Arr_InV_REFUND: Arr_InV_REFUND,
                      Arr_InV_CANCEL:Arr_InV_CANCEL,
                      Arr_InV_AuX_reCS_ID: Arr_InV_AuX_reCS_ID,
                      Arr_InV_testS_GrouP_ID:Arr_InV_testS_GrouP_ID,
                      Arr_InV_testS_GrouP_TiTLE:Arr_InV_testS_GrouP_TiTLE,
                      ConsentForm:""
                     
                      }
                     
                       var vSearchStr = JSON.stringify(SearchFormData);
                    var url ="https://eviewer.fslabs.com.pk:5443/4DACTION/BookingInvoiceCreateNEW"
                    
                        fetch(url,{
                          method:"POST",
                          body:vSearchStr
                        }).then((res)=>res.json()).then((response)=>{
                          console.log(response)
                          if(response[0].Invoice_Status=='Successful'){
                            alert("Invoice : MPL-"+response[0].Invoice_No+" Successfully Created")
                            localStorage.setItem('patient_mobile',"")
                            localStorage.setItem("patient_name","")
                         
                            window.location.href="https://radiology.fslabs.com.pk/Invoices"
                   
                          }else{
                            alert("Sorry Failed !!")

                  setSaveInvoice(false)
                          }
                        })
                  }
                
                }}
                >Save Invoice</Button>
            </Grid>
            
          </Grid>
    </div>

      
      </form>
      <br />
      <br />
      <div style={{ width: "100%" }}>
      
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <MaterialTable
            columns={[
              { field: "Patient_ID", title: "Patient ID" },
              { field: "ToAddress", title: "Title" },
              { field: "Patient_Name", title: "Patient Name" },
              { field: "MobileNo", title: "Mobile" },
              { field: "eMail", title: "Email" },
              { field: "cnic", title: "CNIC" },
              { field: "select", title: "Select" },
            ]}
            data={search_table}
            title={"Patient Record"}
          />
        </div>
      </Modal>
     

      <Modal
        open={openHist}
        onClose={handleCloseHist}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.paper}>
          <MaterialTable
       options={{
      maxBodyHeight:'30em'
      }}
            columns={[
              { field: "id", title: "Invoice ID" },
              { field: "test_code", title: "Test Code" },
              { field: "test_title", title: "Test Title" },
              { field: "booking_date", title: "Booking Date" },
              { field: "booking_time", title: "Booking Time" },
              { field: "amount", title: "Amount" },
              { field: "discount", title: "Discount" },
              { field: "final", title: "Payable" }
            ]}
            data={patient_history}
            title={"Patient History"}
          />
        </div>
      </Modal>
    </div>
  );
}
const patients = [
  { name: "Rehan Raees", id: "mpl101", mobile: "03315999501" },
  { name: "Ali", id: "mpl2338", mobile: "03005031668" },
];
