import {
  BackendError,
  Lockscreen,
  NotFound,
  PasswordReset,
  Signin,
  Signup
} from "./pages";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import AppProvider from "./components/AppProvider/AppProvider";
import Dashboard from "./containers/Dashboard";
import React from "react";
import { render } from "react-dom";
import Login from './Login/Login.js'

import "react-datepicker/dist/react-datepicker.css";
import 'react-html5-camera-photo/build/css/index.css';

render(
  <AppProvider>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route path="/" component={Dashboard} />
     
      </Switch>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById("root")
);

